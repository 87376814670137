export const featuresFree = [
  {
    Title: "Access to Demo Examples",
    Included: true,
    Tooltip:
      "Get access to our Demo Examples, in order to get familiar with the data we provide you."
  },
  {
    Title: "2 free Experiments",
    Included: true,
    Tooltip: "You can create 2 free Eye Tracking Experiments."
  },

  {
    Title: "Full Report",
    Included: true,
    Tooltip: "Heatmaps, Gaze Replays and Gazeplots."
  },

  {
    Title: "Loceye's Customer Support",
    Included: true,
    Tooltip: "Having trouble with our services. We are here to help you."
  }
];

export const featuresCustom = [
  {
    Title: "Third Party Panel Sourcing",
    Included: true,
    Tooltip: "Scale your research with hundreds of participants."
  },
  {
    Title: "Volume Discount",
    Included: true,
    Tooltip: "Pay less, research more."
  },
  {
    Title: "Custom Demographics",
    Included: true,
    Tooltip:
      "Define custom demographics regarding your needs. Leave the rest to us."
  },
  {
    Title: "Raw Data Export",
    Included: true,
    Tooltip:
      "Export the experiments of yours as raw data. Any format like CSV, JSON, EXCEL is supported."
  },
  {
    Title: "Custom Reports",
    Included: true,
    Tooltip: "Heatmaps, Gaze Replays and Gazeplots"
  },
  {
    Title: "Starting from 10$ per response",
    Included: true,
    Tooltip: "Recruit remote participants from our panel, starting from 10$."
  },
  {
    Title: "Integrate with survey tools",
    Included: true,
    Tooltip:
      "Want to extend your survey? We can integrate almost any survey tool like Survey Monkey, Typeform, Google Forms and many more."
  },
  {
    Title: "Training and Best practices",
    Included: true,
    Tooltip:
      "We help you define the scope of the experiments you want to conduct. Your success is our success too."
  }
];
