import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import SectionLayout from "../components/SectionLayout";
import ReactTooltip from "react-tooltip";
import { featuresFree, featuresCustom } from "../components/pricingFeatures";

/**
 * Features: return all the li items of the pricing cards
 * Props
 * Inlcuded: Boolean for cross or check icon
 * Title: Text to display
 * Tooltip: The text to display at the tooltip
 */
const Features = (props) => {
  const Included = props.Included ? null : "checkmark--cross";
  const iconClass = ["checkmark", "bg--primary-1", Included].join(" ");
  const uniqueID = Math.random()
    .toString(36)
    .substring(7);
  return (
    <li>
      <span className={iconClass} />
      <span
        data-tip={props.Tooltip}
        data-for={uniqueID}
        className="features-pricing"
        // data-event="click focus"
      >
        {props.Title}
      </span>
      <ReactTooltip
        place="right"
        id={uniqueID}
        delayShow={200}
        delayHide={40}
        type="info"
        effect="solid"
        className="tooltip-custom"
      />
    </li>
  );
};

class Pricing extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Loceye | Understanding Humans"
          keywords={[`loceye`, `eyetracking`, `marketing`, `ux`, `pricing`]}
        />

        <SectionLayout
          title="Pricing"
          description="Sign up today to get FREE access to our growing library of examples that can explain our features or talk with a Loceye expert about how we can help Fast-moving consumer goods (FMCG) brands, agencies, designers and market research firms use our eye tracking analysis technology to meet their in-market goals."
          customStyle={{ padding: "50px 0 30px 0" }}
        >
          {/* <div
            className="container"
            style={{ padding: "0 2em 0 2em", textAlign: "center" }}
          >
            <div className="row">
              Sign up today to get FREE access to our growing library of
              examples that can explain our features or talk with a Loceye
              expert about how we can help Fast-moving consumer goods (FMCG)
              brands, agencies, designers and market research firms use our eye
              tracking analysis technology to meet their in-market goals.
            </div>
          </div> */}
          <div style={{ marginTop: "4em" }} />
          <div className="container">
            <div className="row">
              <div className="offset-md-1 col-md-5">
                <div
                  className="pricing pricing-1 boxed boxed--lg boxed--border"
                  style={{ minHeight: "40rem" }}
                >
                  <h3 style={{ paddingBottom: "1rem" }}>Free</h3>
                  <span className="h2">
                    <strong style={{ fontSize: "1.5em" }}>0</strong>{" "}
                    <span style={{ color: "#aaa", fontSize: "1rem" }}>$</span>
                  </span>
                  <span className="type--fine-print">Get started now</span>
                  <hr />
                  <ul>
                    {featuresFree.map((data) => (
                      <Features
                        Title={data.Title}
                        Tooltip={data.Tooltip}
                        Included={data.Included}
                      />
                    ))}
                  </ul>
                  <a
                    className="btn btn--primary purchase-btn"
                    href="https://app.loceye.io/"
                  >
                    <span className="btn__text">Start Now</span>
                  </a>
                </div>
              </div>
              <div className="col-md-5">
                <div
                  className="pricing pricing-1 boxed boxed--lg boxed--border boxed--emphasis"
                  style={{ minHeight: "40rem" }}
                >
                  <h3 style={{ paddingBottom: "1rem" }}>Custom</h3>
                  <span className="label">Get a Quote</span>
                  <hr />
                  <ul>
                    {featuresCustom.map((data) => (
                      <Features
                        Title={data.Title}
                        Tooltip={data.Tooltip}
                        Included={data.Included}
                      />
                    ))}
                  </ul>
                  <Link
                    to="/contact"
                    className="btn btn--primary-1 btn__text"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </Layout>
    );
  }
}

export default Pricing;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
